<template>
  <div>
    <OKRLibrary />
  </div>
</template>
<script>
import OKRLibrary from "@/components/library/OKRLibrary.vue";
export default {
  name: "OKRLibrarypage",
  components: {
    OKRLibrary,
  },
};
</script>
